import React               from 'react';
import { navigate }        from 'gatsby';
import { SiteLayout }      from 'lib/container/site-layout/site-layout';
import { TopBar }          from 'lib/container/top-bar/top-bar';
import { ParallaxImage }   from '../../../lib/components/parallax-image/parallax-image.component';
import { Seo }             from '../../../lib/components/seo/seo.componet';
import { Wrapper }         from '../../../lib/components/wrapper/wrapper.component';
import { Content }         from '../../../lib/ink-stains/content.component';
import { InkStainsHeader } from '../../../lib/ink-stains/header.component';
import { useGetImgSet }    from '../../../lib/util/get-images';
import { getContent }      from './data/get-content';

const InkStains2020 = () => {
    const img = useGetImgSet([ 'ink_stains_2021_primary_cover', 'ink_stains_2021_secondary_cover', 'main_ink_stains_share_2021' ]);
    const { content, t } = getContent();
    return (
        <SiteLayout>
            <Seo
                title="Ink Stains 2021"
                image={ img.main_ink_stains_share_2021.src }
            />
            <TopBar onClick={ () => navigate('/') } />
            <Wrapper>
                <InkStainsHeader year="2021" />
                <ParallaxImage
                    src={ img.ink_stains_2021_primary_cover.src }
                    height={ 500 }
                />
                <Content content={ content.primary } side="primary" t={ t } />
                <ParallaxImage
                    src={ img.ink_stains_2021_secondary_cover.src }
                    height={ 500 }
                    margin="xxl"
                />
                <Content content={ content.secondary } side="secondary" t={ t } />
            </Wrapper>

        </SiteLayout>
    );
};
export default InkStains2020;
