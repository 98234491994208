import React       from 'react';
import { Link }    from 'gatsby';
import { Divider } from '../components/divider/divider';
import { Text }    from '../components/text/text';
import { Title }   from '../components/title/title';
import { Wrapper } from '../components/wrapper/wrapper.component';
import { cn }      from '../util/bem';
import { md }      from '../util/md';

type PropsType = {
  side: 'primary' | 'secondary';
  content: any;
  t: any;
}
const className = cn('content');

export const Content = (props: PropsType) => {
    const side = props.content;
    const getSection = (section) => {
        if (!side[ section ]) return null;
        return (
            <Wrapper className={ className(section) }>
                <Title
                    size="h2"
                    type="chapter"
                    color={ props.side }
                    margin="xxl"
                    maxWidth="120rem"
                >
                    <em>{ props.t[ props.side ].title }</em>
                    { ' ' }
                    { props.t[ props.side ][ section ] }
                </Title>
                <Divider transparent margin="sm" />
                { Object.keys(side[ section ]).map((key, index) => (
                    <Wrapper key={ index } className={ className() } margin="xl">
                        <Title size="h5" uppercase margin="xl" color={ props.side }>
                            { props.t[ props.side ][ key ] }
                        </Title>
                        <Wrapper
                            row
                            styles={ { flexWrap: 'wrap' } }
                            columns={ 3 }
                            md={ { columns: 3 } }
                            sm={ { columns: 2 } }
                            xsm={ { columns: 1 } }
                        >
                            { side[ section ][ key ].map((article, aIndex) => (
                                <Wrapper
                                    key={ aIndex }
                                    padding={ { right: 'xxl', bottom: 'xxl' } }
                                    margin={ { bottom: 'sm' } }
                                    hover={ { color: 'secondary' } }
                                    xsm={ { padding: { right: 'off' }, margin: { bottom: 'xxs' }, color: 'key' } }

                                >

                                    <Link
                                        className={ className('item') }
                                        to={ `/ink-stains${ article.fields.slug }` }
                                        state={ {
                                            modal:    true,
                                            noScroll: true
                                        } }
                                        key={ aIndex }
                                        style={ { width: '100%' } }
                                    >
                                        <Wrapper hover={ { color: props.side } } style={ { width: '100%' } }>
                                            <Divider margin="sm" />
                                            { section === 'winner'
                                              && <Text size="sm" margin="xxs"><em>{ article.frontmatter.label }</em></Text> }
                                            <Title size="h4" uppercase margin="xxs">{ article.frontmatter.title.replace('_', '').replace('_', '') }</Title>
                                            <Text size="sm">{ `${ article.frontmatter.author } (${ article.frontmatter.grade }) ` }</Text>
                                        </Wrapper>
                                    </Link>
                                </Wrapper>
                            )) }
                        </Wrapper>

                    </Wrapper>
                )) }
            </Wrapper>
        );
    };
    return (
        <>
            { Object.keys(side).map((key) => getSection(key)) }
        </>
    );
};
