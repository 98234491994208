import { graphql, useStaticQuery } from 'gatsby';

export const getAllPosts = () => {
    const data = useStaticQuery(graphql`
      query GetAllPostsInkStains2021 {
          allMdx(sort: {fields: frontmatter___order}, filter: {fileAbsolutePath: {regex: "/(ink-stains/2021)/"}}) {
              nodes {
                  headings {
                      value
                      depth
                  }
                  fields {
                      slug
                  }
                  frontmatter {
                    author
                    chapter
                    grade
                    order
                    side
                    title
                    program
                    label
                  }
              }
          }
      }
  `);
    return data.allMdx.nodes;
};

export type ContentTypes = {
  side: 'secondary' | 'primary';
  section: 'winners' | 'submissions';
}
const t = {
    primary: {
        title:           'Primary',
        winner:          'PSI Young Authors 2021',
        kg_poetry:       'KG - G3 Poetry',
        kg_narrative:    'KG - G3 Narrative',
        g_4_5_poetry:    'G4 - 5 Poetry',
        g_4_5_narrative: 'G4 - 5 Narrative'
    },

    secondary: {
        title:          'Secondary',
        winner:         'Alexander Literary AwardS Winners, Honorable Mentions and Contest Submissions 2021',
        submissions:    'Alexander Literary Award Contest Submissions 2020',
        lower:          'Lower MYP',
        upper:          'Upper MYP',
        dp:             'DP',
        theme:          'Theme',
        'lower-poetry': 'Lower MYP Poetry',
        'lower-prose':  'Lower MYP Prose Fiction',
        'lower-theme':  'Theme'
    }
};

export const getContent = () => {
    const content = {
        primary: {
            winner: {
                kg_poetry:       [],
                kg_narrative:    [],
                g_4_5_poetry:    [],
                g_4_5_narrative: []
            }
        },
        secondary: {
            winner: {
                theme: [],
                lower: [],
                upper: [],
                dp:    []
            }
            // submissions: {
            //     'lower-poetry': [],
            //     'lower-prose':  [],
            //     'lower-theme':  []
            // }
        }
    };

    const articles = getAllPosts();
    console.log(articles.find((art) => art.frontmatter.author === 'Roshan Basu'));
    articles.map((article) => {
        const a = article.frontmatter;
        if (a.side === 'secondary') {
            return content.secondary[ a.chapter ][ a.program ].push(article);
        }
        if (a.side === 'primary') {
            return content.primary[ a.chapter ][ a.program ].push(article);
        }
        return null;
    });
    return { content, t };
};
