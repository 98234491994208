import React       from 'react';
import { Title }   from '../components/title/title';
import { Wrapper } from '../components/wrapper/wrapper.component';

type PropsType = {
    year: string;
};

export const InkStainsHeader = (props: PropsType) => (
    <>
        <Title size="h5" uppercase margin="sm">{ `Summer ${ props.year }` }</Title>
        <h1 style={ { fontSize: '14vw', lineHeight: 1, marginBottom: '1rem', maxWidth: '100vw' } }>
            INK
            <br />
            STAINS
            { ' ' }
        </h1>
        <Wrapper
            flex={ 1 }
            margin="xxl"
        />
    </>
);
